.formone{
    height: auto;
    width: 100vw;
  }
  .formsection{
    height: auto;
    width: 100vw;
    display: flex;
  }
  .formsection-right-sec-inphd{
    width: 30%;
  }
  .formsection-right-sec-inp{
    width: 60%;
    height: 6vh;
    width: 100%;
    border: 2px solid black;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
  
  }
   .formsection-right-sec-inp input {
    background-color: transparent;
    border: 0px solid;
    height: 100%;
    width: 80%;
    outline: none;
}
  .formsection-left{
    height: 70vh;
    width: 100%;
    background-color: #6c757d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2%;
  }
  .formsection-right{
    height: 90vh;
    width: 50vw;
    background-color: #E5E3F3;
    padding-left:5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .frmsecleft{
    display: flex;
    flex-direction:row ;
    text-align: center;
    font-size: larger;
    /*font-style: italic;*/
    color: antiquewhite;
    margin-top: 6px;
  }
  .frmsecleft-tx{
    width: 30%;
    text-align: right;
    padding-right: 5%;
  }
  .frmsecleft-drpdwn{
    width: 40%;
  }
  .frmsecleft-txprt{
    margin-left: 30%;
    width: 60%;
    text-align: left;
    font-style: italic;
  }
  .expr-prc{
    display: flex;
    margin-left: 30%;
   gap: 5vw;
  }
  .frmsecleft-btn-div{
    margin-left: 30%;
    width: 60%;
  }
  .frmsecleft-btn-div-frm2{
    margin: 0%;
    width: 100%;
  }
  .frmsecleft-btn-div-frm2>button{
    width: 100%;
    height: 6vh;
    color: #6c757d;
    background-color: antiquewhite;
    border: transparent;
    font-weight: bold;
    border-radius: 4px;
    /* font-size: 1.2rem; */
    
    }
  .frmsecleft-btn-div-frm2>button:hover{
    background-color: tan;
    color: #6c757d;
  }

  .frmsecleft-btn-div>button{
    width: 100%;
    height: 6vh;
    color: #6c757d;
    background-color: antiquewhite;
    border: transparent;
    font-weight: bold;
    border-radius: 4px;
  /* font-size: 1.2rem; */
  
  }


  .formsection-right-sec-hd{

   display: flex;
   justify-content: center;
   align-items: center;
  }
  .formsection-right-sec-bd{
    text-align: left;
    margin-left: 15%;
    font-style: italic;
  }
  .formsection-right-sec-2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   width: 100%;
   font-weight: 1000;
   /* font-size: 1.4rem; */
  }
  .formsection-right-sec{
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .inp-field{
 border: none;
 width: 80%;
  }
  .formsection-right-sec-3{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    /* font-size: 1.4rem; */
  }
  /* .formsection-right-sec-3{ 

  } */
  .btn-div>button{
width:100%;
color: white;
background-color: #093FAA;
border: transparent;
height: 2rem;
/* font-size: 1.2rem; */
  }

  .textbg{
    font-size: 1.5rem;
    font-weight: 1000;
  }
  
.frmsecleft-dt{
  margin:10%;
height: 30vh;
  width: 80%;
  background-color: white;
}

.formsection-right-chart{
  height: 90vh;
  width: 50vw;
  background-color: #E5E3F3;
  padding-left:5%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frmsecleft-amtmnt{
  display: flex;
}

.text-bg-2{
  font-weight: 1000;
}
.inp-field-strike{
  width: 100%;
  border: none;
  padding-left: 2%;
  height: 40%;
  border-radius: 6px;
}

.inp-field-usdc{
  width: 100%;
  border: none;
  height: 40%;
  padding-left: 2%;
  border-radius: 6px;
}

.inp-comment-usdc{
  font-size: 14px;
  margin-right: 10px;
  text-align: end;
  font-style: italic;

}

.inp-comment-usdc-max{
  font-size: 14px;
  margin-right: 10px;
  text-align: end;
  color: #b0cbff;
  font-style: italic;
  cursor: pointer
}

.inp-comment-usdc-error{
  font-size: 14px;
  margin-right: 10px;
  text-align: end;
  color: #fd9494;
  font-style: italic;
}



.drdnrsltbx{
  width: 70px;
  /* height: 5vh; */
  text-align: center;
  border-radius: 10px;
  font-size: large;
  box-shadow: .5px .5px .5px 1px lightgray;
}
.drdnrsltbx-2{
  width: 170px;
  /* height: 5vh; */
  text-align: center;
  border-radius: 10px;
  font-size: large;
  box-shadow: .5px .5px .5px 1px lightgray;
}

.button-preview {
  color: antiquewhite;
  background-color: antiquewhite;
}
