/*input[type=number]::-webkit-inner-spin-button,*/
/*input[type=number]::-webkit-outer-spin-button {*/
/*  opacity: 1;*/
/*}*/

.banner{
  height: fit-content;
  background-color: #f6c7c9;
  display: flex;
  flex-direction: row;
  color: brown;
  padding-left: 1%;
}

.banner>div>button{
  height: 100%;
  background-color:brown;
  color: white;
  border:1px solid brown;
  justify-content: center;
  border-radius: 5px;
}
.banner>div{
  width: 80%;
}

.text-banner{
  display: flex;
  justify-content: center;
  padding-top: 1vh;
  padding: 1%;
}
.buttondiv{
  text-align: end;
  margin: auto;
  margin-right: 20px;
  flex-basis: fit-content;
}
.btnbannerpart{
  display: flex;
  align-items: center;
  justify-content: end;
}
.btnbannerpart>button{
background-color: rgb(255, 6, 139);
color: white;
border: rgb(255, 6, 139);;
}
.header{
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.5rem;
  background-color: antiquewhite;
}
.cnctwltbtn{
  width: 100%;
  height: fit-content;
  color:#093FAA;
  background-color: white;
  box-shadow: 0 0 4px 0px #093FAA;
  border: white;
}
.cnctwltbtn:hover{
background-color:#093FAA;
color: white;
}
.btnrfrnw{
  width: 100%;
  height: 40px;
  color:white;
  background-color:#093FAA;
  border:#093FAA;
}
.loading{
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav a:hover{
  font-weight: bold;
  color: white;
}

/*.navbar-brand*/
/*{*/
/*  position: relative;*/
/*  background: url(../public/logo.png);*/
/*  width: 170px;*/
/*  !*left: 15px;*!*/
/*  !*max-height: 70px; !* height of the navbar *!*!*/
/*  max-height: 100%;*/
/*}*/

.navbarlink{
  text-decoration: none;
  font-size: larger;
  color:  antiquewhite;
  font-weight: 1000;
}

  .loading_spinner{
    position: fixed;
    height: 100%;
    width:100%;
    background:white;
    top: 0;
    z-index: 100000;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: gray;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

.tablep-c {
  overflow-x:auto;
  padding-right: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  align-items: center;
  /*border: 2px solid black;*/

}

.my-table {
  width: 100%;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.modal-row {
  padding-top: 0.5rem;
}

.modal-col {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: thin solid #e0e0e0;
}

.modal-col-borderless {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border: transparent;
  /*border: thin solid #e0e0e0;*/
}

.modal-col-borderless-sm {
  padding-top: 0rem;
  padding-bottom: 0.5rem;
  border: transparent;
  /*border: thin solid #e0e0e0;*/
}

.button-width {
  /*margin-left: 20%;*/
  /*margin-right: 20%;*/
  width: 100%;
}

div.container-fluid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}