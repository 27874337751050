@font-face {
  font-family: 'Montserrat';
  /*src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');*/

  /*src: local('Montserrat'), url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');*/
  src: local('Geomanist'), url('./fonts/Geomanist/geomanist-regular-webfont.ttf') format('truetype');
  /*src: local('Montserrat'), url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');*/
  /*src: local('Montserrat'), url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');*/
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Montserrat, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
